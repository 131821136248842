import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, getProperty } from "@gh/shared";

import { IntroTeaser } from "../common/intro-teaser";

export const Intro = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section align="center">
      <IntroTeaser
        title={title}
        content={content}
        teaser={<GatsbyImage alt={title} image={image} />}
      />
    </Section>
  );
};

Intro.defaultProps = {
  data: {},
};

Intro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }).isRequired,
};
