import PropTypes from "prop-types";

import { Section } from "@gh/shared";

import { GridImages } from "../common/grid-images";

export const Grid = ({ data }) => (
  <Section>
    <GridImages {...data} />
  </Section>
);

Grid.defaultProps = {
  data: {},
};

Grid.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.shape({
      top_left: PropTypes.object.isRequired,
      top_right: PropTypes.object.isRequired,
      bottom_left: PropTypes.object.isRequired,
      bottom_right: PropTypes.object.isRequired,
    }),
    texts: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      banner: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
