import PropTypes from "prop-types";

import { Section, getProperty } from "@gh/shared";

import { ServicesList } from "../common/services-list";

export const Services = ({ data }) => {
  const title = getProperty(data, "title");
  const labels = getProperty(data, "labels") || [];

  return (
    <Section align="center">
      <ServicesList title={title} labels={labels} />
    </Section>
  );
};

Services.defaultProps = {
  data: {},
};

Services.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
};
